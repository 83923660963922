body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.swal2-container {
  z-index: 5000 !important;
}

.swal-confirm-btn {
  background-color: #213740 !important;
}

.swal-cancel-btn {
  background-color: #9e9e9e !important;
}